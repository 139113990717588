<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2 align-center">
      <div class="logo-wrapper">
        <b-img :src="appLogoImage" alt="logo" />
      </div>
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1 text-center">
            {{ appDesc }}
          </h2>
        </b-link>
        <b-alert
          v-if="checkLogoutCause === 'invalid-session'"
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>Votre session a expiré. <br />Veuillez vous reconnecter</span>
          </div>
        </b-alert>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" label="Adresse e-mail">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
                message="azerty"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="johndoe@gs2e.ci"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="isLoading === false"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Connexion
            </b-button>
            <!-- LOADER -->
            <b-button
              v-if="isLoading === true"
              variant="primary"
              block
              disabled
            >
              <b-spinner small />
              Connexion en cours...
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
  BImg,
  BAlert,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BImg,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              let status = response.status;
              let api = response.data;
              let message = response.data.message;

              if (status === 200) {
                let { user, accessToken } = api.data;

                if (!user.ability) {
                  user.ability = [
                    {
                      subject: "all",
                      action: "manage",
                    },
                  ];
                }

                useJwt.setToken(accessToken);
                useJwt.setRefreshToken(accessToken);
                localStorage.setItem("userData", JSON.stringify(user));

                this.$ability.update(user.ability);

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', user.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router
                  .replace(getHomeRouteForLoggedInUser(user.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Bienvenue ${user.fullname || user.email}`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Connexion éffectué avec succès en tant que ${user.role}!`,
                      },
                    });
                  });
              } else {
                let errors = api.data;

                if (typeof errors === "array") {
                  for (let x in errors) {
                    let item = errors[x];
                    this.swalError("Erreur", item, "error");
                  }
                } else {
                  this.swalError("Erreur", errors, "error");
                }
                this.isLoading = false;
              }
            })
            .catch((error) => {
              // Remove userData from localStorage
              localStorage.removeItem("userData");

              // Reset ability
              this.$ability.update(initialAbility);
              //this.$refs.loginForm.setErrors(error.response.data.error)
              console.log(error);
              this.swalError("Erreur", error.data, "error");
              this.isLoading = false;
            });
        }
      });
    },
    toastError(title, message, type) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: "WarningIcon",
          variant: type,
          text: message,
        },
      });
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    checkLogoutCause() {
      let query = this.$route.query;
      if (query) {
        return query.ref;
      } else {
        return "";
      }
    },
  },
  created() {
    console.log("Logout", this.checkLogoutCause);
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-wrapper {
  text-align: center;
}
</style>
